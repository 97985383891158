import BoardIconSrc from '../../assets/images/boardIcon.png'
import ShakeHandsIconSrc from '../../assets/images/shakeHandsIcon.png'
import LeftBanner from '../../assets/images/imgBanner-2.jpeg'
import SpinnerSrc from '../../assets/images/basketBall.png';

import './ImageWithText.css';

const ImageWithText = () => {
    return (
        <div className="image-text-wrapper">
            <div className='banner-wrapper'>
                <img src={LeftBanner} alt="banner-2" />
            </div>
            <div className="content-wrapper">
                <div className="title">despre Turneu</div>
                <div className="description">3 On Top vă propune o nouă experiență baschetbalistică dedicată oricărei categorii de vârstă, gen sau experiență. Seria se va constitui din 3 turnee de calificare și un turneu final, primul tuneu urmând să aibe loc în <b>Parcul Municipal Vest</b> pe data de <b>28.09 - 29.09</b>. Echipele vor concura pentru calificarea la turneul final unde va asteapta premii in bani de pana la <b>4000 de lei</b> precum si <b>multe alte surprize</b>.</div>
                <button className="button" onClick={() => {window.open('https://drive.google.com/file/d/17St6eCOTbc02k92NOBp0y9N5dHZhgDOg/view?usp=sharing', '_blank')}} >Regulamentul 3 On Top</button>
                <div className="cols-wrapper">
                    <div className="col">
                        <img className="col-image" src={BoardIconSrc} alt="board-icon" width={63} height={63}/>
                        <div className="col-title">1. Formatul Turneului</div>
                        <p className="paragraph">→ Turneul se va desfășura în format de grupe formate din 3, până la 7 echipe</p>
                        <p className="paragraph">→ Grupele se vor desfășura în format fiecare cu fiecare iar după fiecare turneu se va face un clasament general intermediar</p>
                        <p className="paragraph">→ Meciurile se vor desfășura conform <a target="_blank" rel="noreferrer" href="https://www.frbaschet.ro/public/storage/pages/Regulamente/05-Regulamentul%20Oficial%20al%20Jocului%20de%20Baschet%203x3.pdf">regulamentului FIBA 3x3</a></p>
                        <p className="paragraph">→ Pentru calificarea la turneul final, o echipă trebuie să aibe minim 2 turnee jucate și să fie în top 8 la categoria ei de vârstă</p></div>
                    <div className="col">
                        <img className="col-image" src={ShakeHandsIconSrc} alt="shake-hands-icon" width={63} height={63}/>
                        <div className="col-title">2. Condiții de Participare</div>
                        <p className="paragraph">→ Fiecare echipă trebuie să aibă minim 3 jucători, cu un maxim de 4</p>
                        <p className="paragraph">→ Orice modificare în cadrul echipei se va face până în ziua de miercuri de dinaintea turneului</p>   
                        <p className="paragraph">→ Taxa de participare este de 350 lei/echipă (U14 și U16) și de 500 lei/echipă (restul categoriilor de vârstă) și include: participarea la meciuri, maiou de joc, apă la discreție, posibilitatea de înscriere la concursurile de 3pct, skill challenge și dunk contest</p>
                    </div>
                    <div className="col">
                        <img src={SpinnerSrc} className="col-image" alt="shake-hands-icon" width={63} height={63}/>
                        <div className="col-title">3. Cum mă Înscriu</div>
                        <p className="paragraph">→ Alegem numele echipei</p>
                        <p className="paragraph">→ Alegem căpitanul (persoana de contact) al echipei</p>   
                        <p className="paragraph">→ Număr de telefon</p>
                        <p className="paragraph">→ Achităm taxa de 350 sau 500 de lei în contul <b>RO63RNCB0205179739820001</b> deschis pe numele <b>DAVID FLORIN ARION</b></p>
                        <p className="paragraph">→ Trimitem dovada plății împreună cu detaliile (nume, prenume, data nașterii și mărimea la maiou) fiecărui jucător pe email: <a href="mailto:3ontopploiesti@gmail.com">3ontopploiesti@gmail.com</a></p>
                        <p className="paragraph"><b>→ Vă rugăm la subiectul email-ului să treceți numele echipei</b></p>
                        <p className="paragraph"><b>→ Numărul maxim de echipe: 50</b></p>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ImageWithText;