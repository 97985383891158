import React from 'react';

import HeroBanner1 from '../../assets/images/heroBanner-1.jpeg';
import HeroBanner2 from '../../assets/images/heroBanner-2.jpeg';
import GlobSrc from '../../assets/images/glob.png';
import "./HeroBanner.css";

const HeroBanner = ({ formRef }) => {

  const handleButtonClick = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior:'smooth', block:'start' });
    }
  };

  return (
    <div className="hero-banner">
      <div className="hero-banner-text">
        <div>
          <div className="title">3 On Top Series</div>
          <div className="title">Ploiești 2024</div>
        </div>
        <p>
          Înscrie-ți echipa acum și alătură-te competiției!
        </p>
        <button className="button" onClick={handleButtonClick}>Înscrie-te</button>
      </div>
      <div className="hero-banner-img-wrapper">
        <img src={HeroBanner1} alt="hero-banner" />
        <img src={HeroBanner2} alt="hero-banner2" />
      </div>
      <div className="img-glob-wrapper">
        <img src={GlobSrc} alt="globe" />
      </div>
    </div>
  );
};

export default HeroBanner;
